import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { getModule } from 'vuex-module-decorators'

import authModule from './modules/auth'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  strictMode: process.env.NODE_ENV !== 'production',
  storage: window.localStorage,
})

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {},
  getters: {},
  mutations: {
    RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION,
  },
  actions: {},
  modules: {
    auth: authModule,
  },
  plugins: [vuexLocal.plugin],
})

export default store

export const AuthModule = getModule(authModule, store)
