/* eslint-disable @typescript-eslint/no-explicit-any */
import { NavigationGuardNext, Route } from 'vue-router/types/router'

function middlewarePipeline(
  context: {
    to: Route
    from: Route
    next: NavigationGuardNext<Vue>
    store: any
  },
  middleware: Array<
    (o: {
      to: Route
      from: Route
      next: NavigationGuardNext<Vue>
      store: any
    }) => any
  >,
  index: number
): any {
  const nextMiddleware = middleware[index]

  if (!nextMiddleware) {
    return context.next
  }

  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1)

    nextMiddleware({ ...context, next: nextPipeline })
  }
}

export default middlewarePipeline
