/* eslint-disable @typescript-eslint/no-explicit-any */
import { NavigationGuardNext } from 'vue-router/types/router'

function guest({
  next,
  store,
}: {
  next: NavigationGuardNext<Vue>
  store: any
}): void {
  if (store.getters.userAuthenticated) {
    return next({
      name: 'home',
    })
  }

  return next()
}

export default guest
