import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

import User from '../models/User'

export interface AuthState {
  user: User
  isAuthenticated: boolean
}

@Module({ name: 'auth' })
class Auth extends VuexModule implements AuthState {
  public user = {}
  public isAuthenticated = false

  @Mutation
  private LOGIN_USER(user: User): void {
    this.user = user
    this.isAuthenticated = true
  }

  @Mutation
  private LOGOUT_USER(): void {
    this.user = {}
    this.isAuthenticated = false
  }

  get userAuthenticated(): boolean {
    return this.isAuthenticated
  }

  @Action
  public loginUser(user: User): void {
    this.LOGIN_USER(user)
  }

  @Action
  public logoutUser(): void {
    this.LOGOUT_USER()
  }
}

export default Auth
