import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import auth from './middleware/auth'
import guest from './middleware/guest'
import store from '../store'
import middlewarePipeline from './middlewarePipeline'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: '',
    redirect: 'home',
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ '../views/containers/Dashboard.vue'
      ),
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: '/home',
        name: 'home',
        component: () =>
          import(/* webpackChunkName: "home" */ '../views/pages/Home.vue'),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: '/post-reports',
        name: 'post-reports',
        component: () =>
          import(
            /* webpackChunkName: "post-reports" */ '../views/pages/PostReports.vue'
          ),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: '/upvote-queue',
        name: 'upvote-queue',
        component: () =>
          import(
            /* webpackChunkName: "upvote-queue" */ '../views/pages/UpvoteQueue.vue'
          ),
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/pages/Login.vue'),
    meta: {
      middleware: [guest],
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (!to.meta || !to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next,
    store,
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  })
})

export default router
